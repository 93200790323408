<template>
    <div class="px-3 py-2">
        <b-sidebar backdrop aria-labelledby="sidebar-no-header-title" no-header id="add_gallery" style="direction:ltr;"  width="70rem" right title="معرض الصور" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.photo_gallary}}</span>
        </div>
        <div @click="hide" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <b-button-group  style="width:100%;font-size:0.8rem">
                        <input
                            type="file"
                            multiple
                            accept="image/* application/pdf"
                            :label="$parent.lang.chose_file" 
                            style="width:98%;border:1px solid #ccc;padding:5px;"
                            ref="attached"
                            @change="selectFiles()"
                            >
                    </b-button-group>
                </v-col>
                <v-col cols="12" md="12" sm="12" style="direction:rtl">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>نوع الملف</th>
                                <th>حجم الملف</th>
                                <th>اسم الملف</th>
                                <th>تحميل</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,i) in galleries" :key="i">
                                <td>{{ (item.type && item.type.substring(0,5) == "image") ? "صورة" : "مرفق" }}</td>
                                <td>{{ item.size }}</td>
                                <td style="cursor:pointer" @click="openImg(item.src)">{{ item.title }}</td>
                                <td style="cursor:pointer" @click="openImg(item.src)">{{ $parent.lang.download }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <span v-for="(attach,index) in attached" :key="index">
                        <div class="pa-1 m-2" style="direction:rtl" >
                            {{ attach.name }}
                        <i @click.prevent="attached.splice(index,1)" class="fas fa-trash-alt m-2" style="float:left;"></i></div>
                    </span>
                </v-col>
                
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" variant="success" @click='sendFiles()' class="ma-2" style="width:100px;">{{$parent.lang.upload}}</b-button>
        </div>
      </template>
        </b-sidebar>
      </div>
</template>

<script>
import axios from 'axios'
export default{
    data () {
        return {
            publicPath: window.location.origin,
            card:{
                images:'',
                cardid:'',
            },
            attached:[],
            galleries: [
                
            ]
        }
    },
    mounted(){
        this.getImages();
    },
    created(){
        // this.card.cardid = this.$route.params.cardid;
        //this.getImages();
    },
    methods:{
        getImages(){
            const formdata = new FormData();
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('type','getCardFile');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            formdata.append('auth',auth);
            formdata.append('data[cardid]',this.card.cardid);
            axios.post(
                this.$SAMCOTEC.r_path , formdata
            ).then(
                (response) => {
                    const res = response.data;
                    this.galleries = [];
                    if(typeof res.results.data.files.results !== 'undefined' && res.results.data.files.results !== null){
                        for(let i = 0; i< res.results.data.files.results.length; i++){
                            this.galleries.push({
                                id: res.results.data.files.results[i].id,
                                size: res.results.data.files.results[i].file_size,
                                type: res.results.data.files.results[i].file_type,
                                src: res.results.data.url+res.results.data.files.results[i].file_name,
                                title: res.results.data.files.results[i].file_name,
                            })
                        }
                    }

                }
            )
        },
        openImg(src){
            window.open(src,'width=500,height=500','_blank')
        },
        selectFiles(){
            const files = this.$refs.attached.files;
            this.attached = [...this.attached, ...files];
            
        },
        async sendFiles(){
            //
            const formdata = new FormData();
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('type','uploadFile');
            const auth = this.$cookies.get(this.$store.state.COOKIEPhase);
            formdata.append('auth',auth);
            formdata.append('data[cardid]',this.card.cardid);

            
            for(let i=0;i<this.attached.length;i++){
                formdata.append('data[files]['+i+']',this.attached[i]);
            }
            axios.post(
                this.$SAMCOTEC.r_path , formdata
            ).then(
                (response) => {
                    const res = response.data;
                }
            ).then(() => {
                this.attached = [];
                this.getImages();
            })

        }
    }
}
</script>