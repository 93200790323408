<template>
    <div>
        <b-modal backdrop id="cardApprove" size="xl" aria-labelledby="sidebar-no-header-title" hide-footer  hide-header modal-header-close="no" no-header style="width:75% !important;direction:ltr" right  shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.approve_card}}</span>
                </div>
                <div @click="hide" id="closeMeNow" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>

            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-simple-table>
                        <thead>
                            <tr v-if="customer.cctype==1">
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.customer_type }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ lang.individual_client }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.mobile }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ customer.mobile }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.full_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ customer.full_name }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.owner_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ owner_name }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.id_number }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ customer.id_number }}</td>
                            </tr>
                            <tr v-if="customer.cctype==2">
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.customer_type }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ lang.company_clinet }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.mobile }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ customer.mobile }}</td>
                                <th class="text-center backBlack" style="width:100px;">{{ lang.company_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.fullname }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.company_vatid }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.vatid }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.crt_number }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.crt_number }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.building_no }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.building_no }}</td>
                            </tr>
                            <tr v-if="customer.cctype==2">
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.street_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.street_name }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.branch_number }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.branch_number }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.zipcode }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.zipcode }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.district }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.district }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.city }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.city }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.comapny_address }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.address }}</td>
                            </tr>
                            <tr v-if="customer.cctype==3">
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.entity_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ lang.entity_name }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.branch_name }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ customer.branch_name }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.order_number }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.order_number }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.po_number }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.po_number }}</td>
                                <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{ lang.po_date }}</th>
                                <td class="text-center" style="border:1px solid #000 !important;">{{ company.po_date }}</td>
                            </tr>
                        </thead>
                    </v-simple-table>
                </v-row>
                <v-row>
                    <v-col cols="12" v-for="(car,index) in cars" :key="index">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.plate_number }}</th>
                                    <td class="text-center" style="width:100px;border:1px solid #000 !important;">{{ car.plate_number }}</td>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.car_model }}</th>
                                    <td class="text-center" style="width:100px;border:1px solid #000 !important;">{{ car.model }}</td>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.car_year }}</th>
                                    <td class="text-center" style="width:85px;border:1px solid #000 !important;">{{ car.made_year }}</td>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.car_color }}</th>
                                    <td class="text-center" style="width:85px;border:1px solid #000 !important;">{{ car.color }}</td>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.vin_number }}</th>
                                    <td class="text-center" style="width:100px;border:1px solid #000 !important;">{{ car.vin }}</td>
                                    <th class="text-center backBlack" style="width:85px;border:1px solid #000 !important;">{{ lang.engine_meter }}</th>
                                    <td class="text-center" style="width:85px;border:1px solid #000 !important;">{{ car.engine_meter }}</td>
                                </tr>
                            </thead>
                        </v-simple-table> 
                        <v-row style="margin-left:5px;margin-right:5px;margin-top:5px;margin-bottom:5px;">
                            <v-col cols="12" md="3" sm="12" style="border:1px solid #000">
                                <div v-for="(image,ind) in cimages" :key="ind">
                                    <img :src="image.image" v-if="image.plate == car.plate_number" style="width:100%" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="9" sm="12" style="border-top:1px solid #000;border-bottom:1px solid #000;border-inline-end:1px solid #000">
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th class="text-center backBlack" style="width:140px;border:1px solid #000 !important;">{{lang.worker_name}}</th>
                                            <th class="backBlack" style="width:100px;border:1px solid #000 !important;" v-if="$store.state.licenseType.showItemCode">{{lang.item_code}}</th>
                                            <th class="text-center backBlack">{{lang.description}}</th>
                                            <th class="text-center backBlack" style="width:50px;border:1px solid #000 !important;">{{lang.qtty}}</th>
                                            <th class="text-center backBlack" style="width:100px;border:1px solid #000 !important;">{{lang.item_price}}</th>
                                            <th class="text-center backGreen" style="width:100px;border:1px solid #000 !important;">{{lang.total_without_vat}}</th>
                                            <th class="text-center backRed" style="width:50px;border:1px solid #000 !important;">{{lang.vat}}</th>
                                            <th class="text-center backRed" style="width:50px;border:1px solid #000 !important;">{{lang.ftotal}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item,ind) in tbrows" :key="ind">
                                        <tr v-if="item.plate_number == car.plate_number">
                                            <td style="border:1px solid #000 !important">{{ item.empname }}</td>
                                            <td style="border:1px solid #000 !important" v-if="$store.state.licenseType.showItemCode">{{ item.itemcode }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.item_name }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.qty }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.item_price }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.total }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.vat }}</td>
                                            <td style="border:1px solid #000 !important">{{ item.ftotal }}</td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <div class="flexIt" :style="`direction:${lang.dir}`">
                                    <span v-for="(item,ind) in carStatus" :key="ind">
                                        <div style="border:1px solid #000;margin-inline-end:5px;" v-if="item.plate == car.plate_number" class="p-1">
                                            {{ item.description }}
                                        </div>
                                    </span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-center backBlack">{{ lang.total }}</th>
                                    <th class="text-center backBlack">{{ lang.vat }}</th>
                                    <th class="text-center backBlack">{{ lang.ftotal }}</th>
                                    <th class="text-center backBlack">{{ lang.paid }}</th>
                                    <th class="text-center backBlack">{{ lang.remain }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th class="text-center">{{ full_total }}</th>
                                    <th class="text-center">{{ full_vat }}</th>
                                    <th class="text-center">{{ full_ftotal }}</th>
                                    <th class="text-center">{{ (!invoice.payment.cash || invoice.payment.cash == '') ? 0 : invoice.payment.cash }}</th>
                                    <th class="text-center">{{ remain }}</th>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
            <div class="d-flex text-light align-items-center px-3 py-2 mt-4">
                <b-button type="button" variant="success" @click='createCard()' class="ma-2" style="width:100px;margin-inline:10px;">{{lang.save}}</b-button>
                <b-button type="button" variant="danger" @click='cancel()' class="ma-2" style="width:100px;">{{lang.cancel}}</b-button>
            </div>
    </template>
            
        </b-modal>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            contract_id: 0,
            work_type: 1,
            owner_name: "",
            terms: '',
            showButtomSubmit: true,
            carStatus: [],
            images: [],
            payby: '',
            itemsworkdes:[],
            cars: [],
            date: '',
            received_date: '',
            delivery_date: '',
            full_total: 0,
            full_vat: 0,
            full_ftotal: 0,
            superVisor: 0,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                crt_number: '',
                entity_name: '',
                branch_name: '',
                entity_branch_number: '',
                request_number: '',
                po_number: '',
                po_date: '',
                order_number: '',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                id_number: 1,
            },
            invoice:{
                received_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: '',
                    span: ''
                },
                delivery_date: '',
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                engine_meter: '',
                next_oil_change: '',
                
                invtype:2,
                
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
                card_type: 1,
                old_invoice: ''
            },
            tbrows:[
            ],
            workersList: [],
            customersList: [],
            attached:[]
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        plates: function(){
            let t = {}
            for(let i=0;i<this.tbrows.length;i++){
                t[this.tbrows[i].plate_number] = this.tbrows[i];
            }
            return t;
        },
        remain: function(){
            const tt = (!this.invoice.payment.cash || this.invoice.payment.cash == '') ? 0 : this.invoice.payment.cash
            let t = parseFloat(this.full_ftotal) - parseFloat(tt);
            return t;
        },
        cimages: function(){
            let t = [];
            for(let i=0;i<this.cars.length;i++){
                let test = false;
                for(let j=0;j<this.images.length;j++){
                    if(this.cars[i].plate_number == ''){
                        test = true;
                        continue;
                    }
                    if(this.cars[i].plate_number == this.images[j].plate){
                        t.push({
                            image: this.images[j].image,
                            plate: this.images[j].plate
                        })
                        test = true;
                    }
                }
                if(!test){
                    t.push({
                        image: './img/car-background.png',
                        plate: this.cars[i].plate_number
                    })
                }
            }
            return t;
        }
    },
    methods: {
        modifyIt(note){
            // console.log(note)
            this.contract_id = note.contract_id,
            this.customer_name = note.customer_name,
            this.customer_afforded = note.customer_afforded,
            this.owner_name = note.owner_name,
            this.owner_name = note.owner_name,
            this.carStatus = note.carStatus,
            this.customersList = note.customersList
            this.workersList = note.workersList
            this.tbrows = note.tbrows
            this.invoice = note.invoice
            this.customer = note.customer
            this.company = note.company
            this.full_ftotal = note.full_ftotal
            this.full_vat = note.full_vat
            this.full_total = note.full_total
            this.delivery_date = note.delivery_date
            this.received_date = note.received_date
            this.date = note.date
            this.cars = note.cars
            this.itemsworkdes = note.itemsworkdes
            this.payby = note.payby
            this.images = note.images
            this.attached = note.attached
            this.terms = note.terms
            this.superVisor = note.superVisor
            // // console.log(note);
            // // console.log(this.attached);
        },
        
        async createCard(){
            this.showButtomSubmit = false
            const post = new FormData();
            post.append("type" , "addNewCard"); 
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i=0;i<this.carStatus.length;i++){
                post.append('data[carStatus]['+i+'][description]',this.carStatus[i].description)
                post.append('data[carStatus]['+i+'][plate]',this.carStatus[i].plate)
            }
            for(let i=0;i<this.cars.length;i++){
                post.append('data[cars]['+i+'][color]',this.cars[i].color)
                post.append('data[cars]['+i+'][id]',this.cars[i].id)
                post.append('data[cars]['+i+'][made_year]',this.cars[i].made_year)
                post.append('data[cars]['+i+'][model]',this.cars[i].model)
                post.append('data[cars]['+i+'][plate_number]',this.cars[i].plate_number)
                post.append('data[cars]['+i+'][vin]',this.cars[i].vin)
                post.append('data[cars]['+i+'][engine_meter]',this.cars[i].engine_meter)
                post.append('data[cars]['+i+'][brand]',this.cars[i].brand)
            }
            for(let i=0;i<this.images.length;i++){
                post.append('data[images]['+i+'][image]',this.images[i].image)
                post.append('data[images]['+i+'][plate]',this.images[i].plate)
            }
            for(let i=0;i<this.terms.length;i++){
                post.append('data[terms]['+i+']',this.terms[i])
            }
            for(let i=0;i<this.tbrows.length;i++){
                let d = '';
                Object.values(this.tbrows[i].workers_name).forEach(value => {
                    d += (d == '' ? "" : ",") + value;
                })
                post.append('data[tbrows]['+i+'][workers_name]',d)
                post.append('data[tbrows]['+i+'][vat]',this.tbrows[i].vat)
                post.append('data[tbrows]['+i+'][total]',this.tbrows[i].total)
                post.append('data[tbrows]['+i+'][qty]',this.tbrows[i].qty)
                post.append('data[tbrows]['+i+'][plate_number]',this.tbrows[i].plate_number)
                post.append('data[tbrows]['+i+'][itemcode]',this.tbrows[i].itemcode)
                post.append('data[tbrows]['+i+'][item_price]',this.tbrows[i].item_price)
                post.append('data[tbrows]['+i+'][item_name]',this.tbrows[i].item_name)
                post.append('data[tbrows]['+i+'][ftotal]',this.tbrows[i].ftotal)
            }
            post.append('data[invoice][invtype]',this.invoice.invtype);
            post.append('data[invoice][notes]',this.invoice.notes);
            post.append('data[invoice][payment]',this.invoice.payment.cash);
            post.append('data[invoice][paytype]',this.invoice.paytype);
            post.append('data[invoice][t_discount]',this.invoice.t_discount);
            post.append('data[invoice][userid]',this.invoice.userid);
            post.append('data[invoice][vtype]',this.invoice.vtype);
            post.append('data[invoice][superVisor]',this.superVisor);
            post.append('data[invoice][card_type]',this.invoice.card_type);
            post.append('data[invoice][old_invoice]',this.invoice.old_invoice);

            post.append('data[customer_name]',this.customer_name);
            post.append('data[contract_id]',this.contract_id);
            post.append('data[customer_afforded]',this.customer_afforded);
            post.append('data[work_type]',this.work_type);
            post.append('data[owner_name]',this.owner_name);
            post.append('data[payby]',this.payby);
            post.append('data[delivery_date]',this.delivery_date);
            post.append('data[full_ftotal]',this.full_ftotal);
            post.append('data[full_total]',this.full_total);
            post.append('data[full_vat]',this.full_vat);
            post.append('data[note]',this.note);
            post.append('data[received_date]',this.received_date);
            for(let i=0;i<this.attached.length;i++){
                post.append('data[files]['+i+']',this.attached[i]);
            }
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path, post)
            this.showButtomSubmit = true
            if(response.data.results.data.length == 0){
                let message = this.lang.all_field_require;
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }else{
                let message = this.lang.added_successfuly;
                this.$snotify.success(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                if(this.$store.state.licenseType.showFullsign){
                    window.open('/signcard/'+response.data.results.data.cardid,"_blank");
                }else{
                    window.open('/signcard/'+response.data.results.data.cardid);
                }
                
                document.getElementById('HideMe').click();
                document.getElementById('closeMeNow').click();
                this.$parent.$parent.$parent.closeIT();
                this.$parent.$parent.$parent.$parent.getCurrentCards();
                
                return false;
            }
        },
        cancel(){
            document.getElementById('closeMeNow').click();
        }
    },
}
</script>
<style>
.flexIt{
    display:flex;
    flex-direction: row;
    padding:5px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    justify-items: center;
    align-items: cente;
}
</style>