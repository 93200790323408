
import VueCookies from 'vue-cookies';
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        clientList: [],
        messageStatus: false,
        clientTypes: [
            {text: 'نظام ورش السيارات', value: 'ecars'},
            {text: 'نظام الفوترة', value: 'fatoorh'},
        ]
    },
    getters:{
        billState: (state: Record<string,unknown>): Record<string,unknown> => state,
    },
    mutations:{
        setClientList(state: Record<string,unknown> ,value: Record<string,unknown>): void {
            state.clientList = value
        },
        setMsgStatus(state: Record<string,unknown> ,value: boolean): void {
            state.messageStatus = value
        }
    },
    actions:{
        async getClientList({ commit }): Promise<void>{
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getCheckClient');
            post.append('data[ids]','0');
            post.append('auth',VueCookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/index.php',post
            )
            if(response && response.data){
                commit('setClientList',response.data.results.data)
            }
            
        },
        async sendMsg({commit},value): Promise<void>{
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            console.log("value",value);
            post.append('type','sendMsgToClients');
            for(let i=0;i<value.customers.length;i++){
                post.append("data[customers][]",value.customers[i])
            }
            post.append("data[message]",value.message)
            post.append("data[clientStatus]",value.clientStatus)
            post.append('auth',VueCookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/index.php',post
            ).catch(err => {
                console.log("errr" , err);
            })
            // console.log(response.data);
            if(response && response.data){
                commit('setMsgStatus',true)
            }else{
                commit('setMsgStatus',false)
            }
            
        },

        async addCustomer({commit},value): Promise<void>{
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','addNewClientBill');
            for(const keys in value){
                post.append("data["+keys+"]",value[keys])
            }
            post.append('auth',VueCookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/index.php',post
            ).catch(err => {
                console.log("errr" , err);
            })
            // console.log(response.data);
            if(response && response.data){
                commit('setMsgStatus',true)
            }else{
                commit('setMsgStatus',false)
            }
            
        }
    }
}