<template>
    <div>
        <b-sidebar backdrop id="invoiceApprove" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right  shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{lang.add_invoice}}</span>
                </div>
                <div @click="hide" id="CloseIetss" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12">
                        <br />
                        <br />
                        <br />
                        <label>{{ lang.are_you_sure }}</label>
                        <v-simple-table>
                            <thead>
                                <th>{{lang.total}}</th>
                                <th>{{lang.vat}} 15%</th>
                                <th>{{ lang.ftotal }}</th>
                                <th>{{lang.paid}}</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ total }}</td>
                                    <td>{{ vat }}</td>
                                    <td>{{ ftotal }}</td>
                                    <td>{{ paid }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <br />
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>{{lang.plate_numebr}}</th>
                                    <th>{{lang.item_code}}</th>
                                    <th>{{lang.description }}</th>
                                    <th>{{lang.qtty}}</th>
                                    <th>{{lang.price}}</th>
                                    <th>{{lang.total}}</th>
                                    <th>{{lang.vat}}</th>
                                    <th>{{lang.ftotal}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in invrow" :key="index">
                                    <td>{{ item.plate_number }}</td>
                                    <td>{{ item.itemcode }}</td>
                                    <td>{{ item.item_name }}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.item_price }}</td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.vat }}</td>
                                    <td>{{ item.ftotal }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">{{lang.save}}</b-button>
                <b-button type="button" variant="danger" @click='cancel()' class="ma-2" style="width:100px;">{{ lang.cancel }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            work_type:1,
            total: '',
            vat: '',
            ftotal: '',
            paid: '',
            invrow:[],
            cardid: 0,
        }
    },
    computed: {
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
    },
    methods: {
        modifyIt(note){
            this.total = note.total,
            this.vat = note.vat
            this.ftotal = note.ftotal
            this.paid = note.paid
            this.work_type = this.$parent.$parent.invoice.work_type
            this.invrow = []
            for(let i=0;i<this.$parent.$parent.tbrows.length;i++){
                this.invrow.push(this.$parent.$parent.tbrows[i])
            }
            for(let i=0;i<this.$parent.$parent.tbrows1.length;i++){
                this.invrow.push(this.$parent.$parent.tbrows1[i])
            }
        },
        addUser(){
            this.$parent.$parent.addInvoice()
            document.getElementById('CloseIetss').click();
        },
        cancel(){
            document.getElementById('CloseIetss').click();
        }
    },
}
</script>