<template>
    
    <div>

    </div>

</template>
<script>
export default{
    props:['items'],


}
</script>
<style>

</style>