<template>
  <v-card id="create" style="margin-left:20px;margin-bottom: 20px;">
    <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="left"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-account-circle
          </v-icon>
        </v-btn>
      </template>
      <v-btn
          fab
          dark
          small
          color="green"
          title="التقارير"
          @click="goToPage('/reports/cards-reports')"
      >
        <i class="fas fa-clipboard-list"></i>
      </v-btn>
      <v-btn
          fab
          dark
          small
          title="المحاسبة"
          color="indigo"
          @click="goToPage('/finance/inreceipt')"
      >
        <i class="fas fa-money-bill-alt"></i>
      </v-btn>
      <v-btn
          fab
          dark
          small
          title="الاعدادات"
          color="red"
          @click="goToPage('/settings')"
      >
        <i class="fas fa-cog"></i>
      </v-btn>
      <v-btn
          fab
          dark
          small
          title="نقاط البيع"
          color="green"
          @click="goToPage('/SAMPOS')"
      >
        <i class="fas fa-vote-yea"></i>
      </v-btn>
    </v-speed-dial>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    direction: 'top',
    fab: false,
    fling: false,
    hover: true,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: 'slide-y-reverse-transition',
  }),
  computed: {
    activeFab () {
      switch (this.tabs) {
        case 'one': return { class: 'purple', icon: 'account_circle' }
        case 'two': return { class: 'red', icon: 'edit' }
        case 'three': return { class: 'green', icon: 'keyboard_arrow_up' }
        default: return {}
      }
    },
  },
  methods:{
    goToPage(url){
      if(url == '/SAMPOS'){
          window.open('http://pos.samcotec.com/','_blank')
      }
      else{
          this.$router.push(url)
      }
    }
  },
  watch: {
    top (val) {
      this.bottom = !val
    },
    right (val) {
      this.left = !val
    },
    bottom (val) {
      this.top = !val
    },
    left (val) {
      this.right = !val
    },
  },
}
</script>

<style>
/* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: fixed;
  bottom:50px
}

#create .v-btn--floating {
  position: relative;
}
</style>