const convertNumber = {
    toEng(string) {

        return string.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
            return c.charCodeAt(0) & 0xf;
        });
    },

    toArabicNumerals(str) {
        return str.split('').map(chr => {
          if ('0' <= chr && chr <= '9') {
            return String.fromCharCode(+chr + 1632)
          }
  
          if ('\u0660' <= chr && chr <= '\u0669') {
            return chr
          }
  
          return ''
        }).join('')
      }
}

export default convertNumber;