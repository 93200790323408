
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        report: [],
    },
    getters:{
        inOutState: state => state,
    },
    mutations:{
        setReport(state,value) {
            state.report = value
        }
    },
    actions:{
        async getInOutRep({commit},values){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInOutRep');
            post.append('data[sdate]',values.sdate);
            post.append('data[edate]',values.edate);
            post.append('auth',values.auth);
            const response = await axios.post(
                '/api/index.php',post
            )
            // console.log(response.data);
            
            if(response && response.data){
                commit('setReport',response.data.results.data)
            }
            
        }
    }
}