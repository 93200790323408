
import VueCookies from 'vue-cookies';
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        clientList: [],
        messageStatus: false,
        clientTypes: [
            {text: 'نظام ورش السيارات', value: 'ecars'},
            {text: 'نظام الفوترة', value: 'fatoorh'},
        ]
    },
    getters:{
        supportState: (state: Record<string,unknown>): Record<string,unknown> => state,
    },
    mutations:{
        setClientList(state: Record<string,unknown> ,value: Record<string,unknown>): void {
            state.clientList = value
        },
        setMsgStatus(state: Record<string,unknown> ,value: boolean): void {
            state.messageStatus = value
        }
    },
    actions:{
        async getClientList({ commit }): Promise<void>{
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getClients');
            post.append('data[ids]','0');
            post.append('auth',VueCookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/index.php',post
            )
            console.log("Clients",response.data);
            if(response && response.data){
                commit('setClientList',response.data.results.data)
            }
            
        },
        
    }
}