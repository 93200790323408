<template>
    <div>
        <b-modal
            id="add_addporch"
            ref="modaadd_addporchl"
            :title="lang.purchase_invoice"
            size="xl"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            
        >
            <template #modal-header >
                <div class="w-100 flexit" style="direction:rtl;text-align:center">
                    <div style="margin-bottom:5px!important;font-size:1.3rem;border:2px solid #fff !important;width:150px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                        {{ lang.purchase_invoice }}
                    </div>
                </div>
            </template>
        
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.billno}}</label>
                        <b-form-input class="inborder"
                            :label="lang.billno"
                            v-model="bill_number"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="bill_date"
                            width="290px"
                            persistent
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <label>{{lang.date}}</label>
                                <b-form-input class="inborder"
                                v-model="bill_date"
                                :label="lang.date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                ></b-form-input>
                            </template>
                            <v-date-picker
                                v-model="bill_date"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                                >
                                Cancel
                                </v-btn>
                                <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(bill_date)"
                                >
                                OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.vendorid}}</label>
                        <b-form-input class="inborder"
                            :label="lang.vendorid"
                            v-model="vendorid"
                            @change="vendorCheck()"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.vendor_name}}</label>
                        <b-form-input class="inborder"
                            :label="lang.vendor_name"
                            v-model="vendor"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-form-input class="inborder"
                            :label="lang.mobile"
                            v-model="mobile"
                            @change="vendorCheck()"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.vendor_vatids}}</label>
                        <b-form-input class="inborder"
                            :label="lang.vendor_vatids"
                            v-model="vat_number"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.vendor_address}}</label>
                        <b-form-input class="inborder"
                            :label="lang.vendor_address"
                            v-model="vendor_address"
                        ></b-form-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <label>{{lang.invoice_notes}}</label>
                        <b-form-input class="inborder"
                            :label="lang.invoice_notes"
                            v-model="inv_notes"
                        ></b-form-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.payment_method}}</label>
                        <b-input-group>
                            <b-form-select class="selborder" 
                                v-model="paytype"
                                :options="paytypes"
                                @change="getIT(0)"
                            ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{ lang.pay_from_custdy }}</label>
                        <b-input-group>
                            <b-form-select class="selborder" 
                                v-model="custid"
                                :options="custo"
                                @change="getIT(1)"
                            ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.vat_type}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="vattype"
                            :options="vattypes"
                            :label="lang.vat_type "
                            style="background:red !important"
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.type}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="invtype"
                            :options="invtypes"
                            :label="lang.type"
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="2" sm="12">
                        <label>{{lang.bill_type}}</label>
                        <b-input-group>
                        <b-form-select class="selborder" 
                            v-model="cashajl"
                            :options="cashajls"
                            :label="lang.bill_type"
                            @change="recalkAll()"
                        ></b-form-select>
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div>{{lang.bill_detates}}</div>
                        <v-simple-table striped hover style="width:100%;">
                            <thead>
                                <tr>
                                    <th class="text-center">{{lang.item_code}}</th>
                                    <th class="text-center">{{lang.item_name}}</th>
                                    <th class="text-center">{{lang.item_unit}}</th>
                                    <th class="text-center">{{lang.qtty}}</th>
                                    <th class="text-center">{{lang.item_price}}</th>
                                    <th class="text-center">{{lang.total}}</th>
                                    <th class="text-center">{{lang.vat}}</th>
                                    <th class="text-center">{{lang.ftotal}}</th>
                                    <th class="text-center">{{lang.action}}</th>
                                </tr>
                            </thead>
                            <tbody id="tablerow">
                                <tr v-for="(item,index) in tbrows" :key="index">
                                    <td style="width:100px">{{ item.item_number }}</td>
                                    <td style="width:25%"><b-form-input class="inborder" v-model="tbrows[index].item_name" /></td>
                                    <td>{{ item.unit }}</td>
                                    <td><b-form-input class="inborder" v-model="tbrows[index].qty" @change="recalkAll()" /></td>
                                    <td><b-form-input class="inborder" v-model="tbrows[index].item_price" @change="recalkAll()" /></td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.vat }}</td>
                                    <td>{{ item.ftotal }}</td>
                                    <td style="background:red;border-bottom:10px solid #fff;">
                                        <b-button type="button" variant="danger" class="btn btn-sm" style="width:65px;background:red;border:none" @click="removeItem(index)">{{lang.delete}}</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.item_number"
                                            ref="item_number"
                                            @change="getItems()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.item_name"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.unit"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.qty"
                                            @change="calcKit()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.item_price"
                                            @change="calcKit()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.total"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.vat"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="newrow.ftotal"
                                            @change="calcKit()"
                                            readonly
                                        ></b-form-input>
                                    </td>
                                    <td style="background:green;border-top:10px solid #fff;">
                                        <b-button type="button" class="btn-sm" style="background:green;border:none;" variant="success" @click="addNewRow()">{{lang.add}}</b-button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        الخصم
                                    </td>
                                    <td>
                                        <b-form-input class="inborder"
                                            v-model="discount"
                                            @change="calcTotals()"
                                        ></b-form-input>
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                        
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center"></th>
                                    <th class="text-center">{{lang.totals}}</th>
                                    <th class="text-center">{{ totals.total }}</th>
                                    <th class="text-center">{{ totals.vat }}</th>
                                    <th class="text-center">{{ totals.ftotal }}</th>
                                    <th class="text-center"></th>
                                </tr>
                            </tfoot>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>

        <template #modal-footer="{ close }" >
                <div class="w-100" style="direction:rtl;text-align:center;">
                    <b-button type="button" @click='addPurchase()' id="addPurch" style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ SUBMIT_NAME }}</b-button>
                    <b-button type="button" @click='close()' id="closeme" style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ lang.close }}</b-button>
                </div>
            </template>
        </b-modal>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            vendorid: '',
            bill_number:'',
            modal: false,
            vendor: '',
            mobile: '',
            bill_date: '',
            vat_number: '',
            vendor_address: '',
            paytype: 1,
            vattype: 2,
            full_total:0,
            full_vat:0,
            full_ftotal:0,
            invtype:1,
            inv_notes:'',
            discount:0,
            tbrows:[],
            newrow:{
                item_number: '',
                item_name: '',
                unit: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
            paytypes:[
            ],
            vattypes:[
            ],
            invtypes:[
            ],
            cashajl: 1,
            cashajls:[
            ],
            SUBMIT_NAME:'',
            custodies: [],
            custid: 0
        }
    },
    methods:{
        standUP(){
            this.SUBMIT_NAME = this.lang.add;
            this.newrow.unit = this.lang.pace
            this.paytypes = [
                {text: this.lang.cash_payment, value: 1},
                {text: this.lang.bank_payment, value: 2},
            ]
            this.vattypes = [
                {text: this.lang.prices_without_vat, value: 1},
                {text: this.lang.prices_exclusive_vat, value: 2},
                {text: this.lang.prices_include_vat, value: 3},
            ]
            this.invtypes = [
                {text: this.lang.purchase_invoice, value: 1},
            ]
            this.cashajls = [
                {text: this.lang.paid_invoice, value: 1},
                {text: this.lang.none_paid_invoice, value: 2},
            ]
        },
        getIT(id){
            if(id == 0){
                if(this.paytype != 0){
                    this.custid = 0
                }
            }else{
                if(this.custid != 0){
                    this.paytype = 0
                }
            }
        },
        enableAdd(){
            document.getElementById('addPurch').disabled = false;
        },
        getPayTypes() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getPayTypes");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            post.append('data[pur]',1)
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    for(let i=0;i<res.results.data.length;i++){
                        myar.push(
                            {
                                text: (this.lang.langname == "ar") ? res.results.data[i].type_name : res.results.data[i].type_name_en, 
                                value: res.results.data[i].id,
                                typeid: res.results.data[i].typeid,
                            }
                        );
                    }
                }
                this.custodies = res.results.cust;
                this.paytypes = myar;
                this.paytypes.push({
                    text: 'صرف من العهد',
                    value: 0,
                    typedid: 0
                })
                this.paytype = res.results.data[0].id;
            })
        },
        vendorCheck() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getVendor");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[vendorid]',this.vendorid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(typeof response.data.results !== 'undefined' 
                    && typeof response.data.results.data !== 'undefined'
                    && response.data.results.data.length != 0){
                        this.vendor = response.data.results.data.full_name;
                        this.vat_number = response.data.results.data.vatid;
                        this.vendor_address = response.data.results.data.address;
                        this.mobile = response.data.results.data.mobile;
                    }
            })
        },
        getItems() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.newrow.item_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].product_type == 1){
                        this.newrow.item_number = response.data.results.data[0].itemcode;
                        this.newrow.item_name = response.data.results.data[0].name;
                        this.newrow.item_price = response.data.results.data[0].price;
                    }else{
                        this.newrow.item_number = '';
                        let message = this.lang.this_item_service_item;
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        return false;
                    }
                }
                this.calcKit();
            })
        },

        calcTotals(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;  
            for(let i = 0; i< this.tbrows.length;i++){
                this.full_total = +this.full_total + +this.tbrows[i].total;
                this.full_vat = +this.full_vat + +this.tbrows[i].vat;
                this.full_ftotal = +this.full_ftotal + +this.tbrows[i].ftotal;
            }
        },
        recalckNew(){
            if(this.newrow.item_price == '' || this.newrow.item_price == 0) return false;
            const vt = this.vattype;
            const total = parseFloat(this.newrow.qty) * parseFloat(this.newrow.item_price);
            const cal = this.$calcVat(total,vt);
            this.newrow.total = cal.tot;
            this.newrow.vat = cal.vat;
            this.newrow.ftotal = cal.ftot;
        },
        recalkAll(){
            let newval = [];
            const vt = this.vattype;
            for(let i = 0;i < this.tbrows.length;i++){
                let currow = this.tbrows[i];
                let qty = currow.qty;
                let price = currow.item_price;
                let total = 0;
                let vat = 0;
                let ftotal = 0;
                currow.total = 0;
                currow.vat = 0;
                currow.ftotal = 0;
                if(qty != '' && price != ''){
                    total = +qty * +price;
                    const cal = this.$calcVat(total,vt);
                    total = cal.tot;
                    vat = cal.vat;
                    ftotal = cal.ftot;
                }
                currow.total = total;
                currow.vat = vat;
                currow.ftotal = ftotal;
                newval.push(currow)
            }
            this.tbrows = newval;
            this.calcTotals();
            this.recalckNew();
        },
        calcKit(){
            const vt = this.vattype;
            const qty = this.newrow.qty;
            const price = this.newrow.item_price;
            let total = 0;
            let vat = 0;
            let ftotal = 0;
            this.newrow.total = 0;
            this.newrow.vat = 0;
            this.newrow.ftotal = 0;
            if(qty != '' && price != ''){
                total = +qty * +price;
                const cal = this.$calcVat(total,vt);
                total = cal.tot;
                vat = cal.vat;
                ftotal = cal.ftot;
            }
            
            this.newrow.total = this.$RoundNum(total);
            this.newrow.vat = this.$RoundNum(vat);
            this.newrow.ftotal = this.$RoundNum(ftotal);
            // // console.log(this.newrow)
        },
        addNewRow(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.newrow.qty == 0 || this.newrow.qty == ''){
                this.$snotify.error('الكمية يجب انت تكون رقم غير الصفر', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            // if(this.newrow.item_number == ''){
            //     this.$snotify.error('لا يمكن اضافة صنف بدون رقم الصنف', 'خطــأ', {
            //         timeout: 2000,
            //         showProgressBar: true,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //     });
            //     return false;
            // }
            if(this.newrow.item_name == ''){
                this.$snotify.error('لا يمكن اضافة صنف بدون اسم', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            if(this.newrow.item_price == ''){
                this.$snotify.error('يجب أن يكون سعر الصنف رقما غير صفر', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            
            this.tbrows.push(this.newrow);
            this.newrow = {
                item_number: '',
                item_name: '',
                unit: this.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            }
            // // console.log(this.newrow);
            this.$refs.item_number.focus();
            this.calcTotals();
        },
        removeItem(index){
            this.tbrows.splice(index, 1);
        },
        addPurchase()
        {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.buill_date == ''){
                if(this.bill_date == ''){
                    this.$snotify.error('يرجى اختيار تاريخ الفاتورة', 'خطــأ', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                }
                return false;
            }
            if(this.tbrows.length < 1){
                this.$snotify.error('لا يمكن ادخال فاتورة فارغة', 'خطــأ', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return false;
            }
            
            if(this.invtype == 1){
                if(this.paytype == 0 && this.custid == 0){
                    this.$snotify.error('يجب اختيار طريقة دفع قبل المتابعة أو عهدة من موظف', 'خطــأ', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return false;
                }
                if(this.custid != 0){
                    for(let i=0;i<this.custodies.length;i++){
                        if(this.custodies[i].id == this.custid){
                            if(this.totals.ftotal > this.custodies[i].total){
                                this.$snotify.error('عهدة الموظف لا تغطي تكاليف الفاتورة', 'خطــأ', {
                                    timeout: 2000,
                                    showProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                                return false;
                            }
                        }
                    }
                }
            }
            document.getElementById('addPurch').disabled = true;
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", 'createBill');
            post.append("auth", this.$cookies.get(this.$COOKIEPhase));
            post.append('data[bill][bill_number]',this.bill_number);
            post.append('data[bill][vendorid]',this.vendorid);
            post.append('data[bill][vendor]',this.vendor);
            post.append('data[bill][mobile]',this.mobile);
            post.append('data[bill][bill_date]',this.bill_date);
            post.append('data[bill][vat_number]',this.vat_number);
            post.append('data[bill][vendor_address]',this.vendor_address);
            post.append('data[bill][paytype]',this.paytype);
            post.append('data[bill][vattype]',this.vattype);
            post.append('data[bill][inv_notes]',this.inv_notes);
            post.append('data[bill][invtype]',this.invtype);
            post.append('data[bill][total]',this.totals.total);
            post.append('data[bill][vat]',this.totals.vat);
            post.append('data[bill][ftotal]',this.totals.ftotal);
            post.append('data[bill][cashajl]',this.cashajl);
            post.append('data[bill][discount]',this.discount);
            post.append('data[bill][custid]',(this.cashajl == 1 && this.paytype == 0) ? this.custid : 0);
            for(let i=0;i<this.tbrows.length;i++ ){
                for(var key in this.tbrows[i]){
                    post.append('data[rows]['+i+']['+key+']',this.tbrows[i][key]);
                }
            }
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                
                this.$snotify.success('تمت الاضافة بنجاح', '', {
                    timeout: 2000,
                    showProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
               
                this.vendor = '';
                this.mobile = '';
                this.bill_date = '';
                this.vat_number = '';
                this.vendor_address = '';
                this.paytype = 1;
                this.vattype = 2;
                this.full_total = 0;
                this.full_vat = 0;
                this.full_ftotal = 0;
                this.invtype = 1;
                this.inv_notes = '';
                this.tbrows = [];
                //this.$parent.getInvoice();
                document.getElementById('closeme').click();
            });
        },
        reset(){
            this.vendorid =  '';
            this.bill_number = '';
            this.modal =  false;
            this.vendor =  '';
            this.mobile =  '';
            this.bill_date =  '';
            this.vat_number =  '';
            this.vendor_address =  '';
            this.paytype =  1;
            this.vattype =  2;
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            this.invtype = 1;
            this.inv_notes = '';
            this.discount = 0;
            this.tbrows = [];
            this.newrow = {
                item_number: '',
                item_name: '',
                unit: this.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
            
            this.cashajl =  1;
            
            this.SUBMIT_NAME = this.lang.add;
            this.custodies =  [];
            this.custid =  0;
        }
    },
    created() {
       //this.addNewRow()
       this.standUP();
       this.getPayTypes();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        custo: function(){
            let t = [{
                text: this.lang.pay_from_cash,
                value: 0
            }];
            
            for(let i=0;i<this.custodies.length;i++){
                t.push({
                    text: this.custodies[i].name,
                    value: this.custodies[i].id
                })
            }
            return t;
        },
        totals: function(){
            let t = {
                total: 0,
                vat: 0,
                ftotal: 0
            };
            let total = 0;
            for(let i=0;i<this.tbrows.length;i++){
                total = parseFloat(total) + parseFloat(this.tbrows[i].total);
            }
            total = parseFloat(total) - parseFloat(this.discount);
            const vt = this.vattype;
            const tx = this.$calcVat(total,vt);
            t.total = this.$RoundNum(tx.tot);
            t.vat = this.$RoundNum(tx.vat);
            t.ftotal = this.$RoundNum(tx.ftot);
            return t;
        }
    }
}
</script>
