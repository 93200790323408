import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies';
import Snotify, { SnotifyPosition } from 'vue-snotify'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "vue-snotify/styles/material.css";
const options = {
    toast: {
      position: SnotifyPosition.rightBottom
    }
  }


Vue.use(Vuetify);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.use(Snotify)



export default new Vuetify({
  rtl: true,
});
